export enum CheckoutStatus {
  CREATED = 'CREATED',
  COMPLETED = 'COMPLETED',
}

export enum PaymentProcessor {
  STRIPE = 'STRIPE',
  PAYPAL = 'PAYPAL',
}

export enum CaptureMethod {
  AUTHORIZE = 'authorize',
  CAPTURE = 'capture',
}
