import { BuybackQuoteUS } from '@/components/buyback/common';
import { DeviceConditions, DeviceVariantT } from './types';

export const COMMON_VARIANTS: DeviceVariantT[] = [
  {
    variant: 'screen',
    options: [
      DeviceConditions.FLAWLESS,
      DeviceConditions.MINOR,
      DeviceConditions.MODERATE,
      DeviceConditions.CRACKED,
    ],
  },
  {
    variant: 'housing',
    options: [
      DeviceConditions.FLAWLESS,
      DeviceConditions.MINOR,
      DeviceConditions.MODERATE,
      DeviceConditions.CRACKED,
    ],
  },
  {
    variant: 'condition',
    options: ['Yes', 'No'],
  },
];

export const CONDITIONS = [
  { label: 'It powers on and off without issue' },
  { label: 'All of the buttons and cameras work' },
  { label: 'The battery has at least 80% health', info: 'To be added' },
  {
    label: 'Display is free of defects (dead pixels, white spots, or burn-in)',
    info: 'To be added',
  },
  { label: 'Must be paid off and free of any financial obligations' },
];

export const MAIN_STEPS = 3;
export const MINIMUM_SUB_STEPS = 2;

export const DEFAULT_FINAL_QUOTE: BuybackQuoteUS = {
  customer: null,
  otherIssues: [],
  originalAccessories: [],
  storage: '',
  housing: '',
  screen: '',
  finalQuote: 0,
  deviceName: '',
  brand: '',
  category: '',
  vendorSlug: '',
  vendorName: '',
  collectionType: 'UPS Shipping',
  collectionDetails: null,
  paymentMethod: '',
  paymentDetails: {
    amount: {
      type: '',
      value: '',
    },
    creditorAccount: {
      identification: '',
    },
    creditor: {
      name: '',
      type: '',
      postalAddress: {
        addressLine: [''],
        city: '',
        country: '',
      },
    },
    creditorAgent: {
      branch: {
        identification: '',
        name: '',
      },
      institution: {
        identification: '',
        name: '',
      },
    },
    paymentMethod: '',
    paymentType: {
      serviceLevel: '',
    },
    paymentIdentification: {
      endToEndIdentification: '',
      instructionIdentification: '',
    },
    requestedExecutionDate: '',
  },
  image: '',
  id: '',
  productId: '',
  hasSubmit: false,
  orderId: '',
  deviceIssues: {},
  rid: '',
  condition: '',
  carrier: '',
  deviceFunctional: '',
  unlockedStatus: '',
  vendorMailInLocation: null,
  vendorCommission: 0,
  watchCaseMaterial: '',
  cpu: '',
  ram: '',
  connectivity: '',
  marketingUpdates: false,
  token: '',
  batteryHealth: '',
};

// All states for US dropdown
export const US_STATES = [
  {
    name: 'Select your state',
    abbreviation: '',
  },
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];
