import { createContext, useContext } from 'react';
import useBuyback from './useBuyback';
import { UseBuybackResult } from './types';

const BuybackContext = createContext<UseBuybackResult | undefined>(undefined);

export const useBuybackContext = () => {
  const context = useContext(BuybackContext);

  if (!context)
    throw new Error('useBuybackContext must be used within a BuybackProvider');

  return context;
};

export const BuybackProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = useBuyback();

  return (
    <BuybackContext.Provider value={value}>{children}</BuybackContext.Provider>
  );
};
