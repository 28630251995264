import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import settings from '@/settings';

export function GoogleTopQualityBadge() {
  const isUsStore = settings.store === 'reebelo-us';

  if (
    typeof window === 'undefined' ||
    document.body.clientWidth < 1024 ||
    !isUsStore
  )
    return null;

  return (
    <Script
      id="merchantverseScript"
      src="https://www.gstatic.com/merchantverse/merchantverse.js"
      strategy="lazyOnload"
      type="text/javascript"
      onLoad={() => {
        if (window?.merchantverse) {
          window.merchantverse.start({
            position: 'RIGHT_BOTTOM',
          });
        }
      }}
      title="Merchant Verse"
    />
  );
}

export const useToggleGoogleTopQualityBadge = () => {
  const isUsStore = settings.store === 'reebelo-us';
  const { pathname } = useRouter();

  useEffect(() => {
    if (!isUsStore) return;
    const googleTQBiFrame =
      document.getElementById('merchantverse-iframe') ||
      document.getElementById('merchantverse-iframe-wrapper');

    if (
      pathname === '/collections/[handle]' ||
      pathname === '/products/[handle]'
    ) {
      if (googleTQBiFrame) googleTQBiFrame.style.display = 'block';

      return;
    }

    if (googleTQBiFrame) googleTQBiFrame.style.display = 'none';
  }, [pathname]);
};
