import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function useRouterTransition() {
  const [isTransitioning, setIsTransitioning] = useState(false);
  const router = useRouter();

  // we have to use hoisted function to be able to remove the event
  function onStart() {
    return setIsTransitioning(true);
  }

  function onStop() {
    return setIsTransitioning(false);
  }
  useEffect(() => {
    router.events.on('routeChangeStart', onStart);
    router.events.on('routeChangeComplete', onStop);
    router.events.on('routeChangeError', onStop);

    return () => {
      router.events.off('routeChangeStart', onStart);
      router.events.off('routeChangeComplete', onStop);
      router.events.off('routeChangeError', onStop);
    };
  }, [router]);

  return isTransitioning;
}
