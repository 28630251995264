import { extractTags } from '@lambda/reebelo/src/tagHelpers';
import { REEBELO_CURRENCY } from '@lambda/reebelo';
import settings from '@/settings';
import type { ReplacedProduct } from './collections/catalogue/helpers';
import { logger } from './logger';

type Item = {
  /** "SKU_12345", */ item_id: string;
  /** "Stan and Friends Tee", */ item_name: string;
  /** "Google Merchandise Store", */ affiliation: string;
  /** "SUMMER_FUN", */ coupon: string;
  /** "USD", */ currency: string;
  /** 2.22, */ discount: number;
  /** 0, */ index: number;
  /** "Google", */ item_brand: string;
  /** "Apparel", */ item_category: string;
  /** "Adult", */ item_category2: string;
  /** "Shirts", */ item_category3: string;
  /** "Crew", */ item_category4: string;
  /** "Short sleeve", */ item_category5: string;
  /** "related_products", */ item_list_id: string;
  /** "Related Products", */ item_list_name: string;
  /** "green", */ item_variant: string;
  /** "L_12345", */ location_id: string;
  /** 9.99, */ price: number;
  /** 1 */ quantity: number;
  promotion_id: string;
  promotion_name: string;
};

// type GtmECommerce = {
//   currency?: string;
//   value?: number;
//   items: Array<Partial<Item>>;
// };

// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#product_views_and_interactions
type GtmECommerceEvents =
  | 'view_item_list'
  | 'view_item'
  | 'select_item'
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'productClick'
  | 'addToCart'
  | 'view_cart'
  | 'checkout'
  | 'searchProducts'
  | 'searchOfferResultClick'
  | 'searchCollectionResultClick';

type DataLayer = {
  push: (
    data: { event?: GtmECommerceEvents; ecommerce: any } | { ecommerce: null },
  ) => void;
};

declare global {
  interface Window {
    dataLayer: any[];
  }
}

export const GA_UA_ID = settings.marketing.gaUaId;
const isProd =
  process.env.NEXT_PUBLIC_STAGE === 'prod' || process.env.STAGE === 'prod';

export function triggerInitalGAdsEvents() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(['js', new Date()]);
  window.dataLayer.push([
    'config',
    'AW-10974721493',
    { allow_enhanced_conversions: true },
  ]);
}

export function triggerInitalEvents() {
  if (!isProd || typeof window === undefined) return;

  window.dataLayer.push(['js', new Date()]);
  // FIXME: Don't use hardcoded ID once you setup for NZ and US.
  window.dataLayer.push(['config', `${GA_UA_ID}`]);
}

export const install = isProd
  ? {
      script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','${settings.marketing.gtm_id}'); window.dataLayer.push(['js', new Date()]); window.dataLayer.push(['config', '${GA_UA_ID}']);`,
      iframe: `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${settings.marketing.gtm_id}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`,
    }
  : null;

function mockDataLayerPush(...args: any[]) {
  // Should happen only in dev. If not we add extra logs
  logger.info('GTM should have sent', ...args, {
    'typeof window': typeof window,
    'process.env.NEXT_PUBLIC_STAGE': process.env.NEXT_PUBLIC_STAGE,
    'window.dataLayer': typeof window !== 'undefined' && window.dataLayer,
  });
}

const dataLayer = () =>
  (isProd
    ? window.dataLayer || { push: mockDataLayerPush }
    : { push: mockDataLayerPush }) as DataLayer;

const CURRENCY = REEBELO_CURRENCY[settings.store];

const formatOffer = (offer: ReplacedProduct): Partial<Item> => {
  const tags = extractTags(offer.tags);

  return {
    item_id: offer.variantId.toString(),
    item_name: offer.title,
    currency: CURRENCY,
    item_brand: tags.brand,
    item_variant: '',
    item_list_id: tags.psku,
    price: offer.price,
    quantity: offer.stock ?? 1,
  };
};

export default {
  event: (event: GtmECommerceEvents | null, ecommerce: any) => {
    if (typeof window === 'undefined') return;

    const eventPayload: {
      event?: GtmECommerceEvents;
      ecommerce: any;
    } = {
      event: event !== null ? event : undefined,
      ecommerce,
    };

    dataLayer().push({ ecommerce: null });
    dataLayer().push(eventPayload);
  },
  eventOffers: (event: GtmECommerceEvents, offers: ReplacedProduct[]) => {
    if (typeof window === 'undefined') return;

    dataLayer().push({ ecommerce: null });
    dataLayer().push({
      event,
      ecommerce: { items: offers.map(formatOffer) },
    });
  },
  genericEvent(event: any) {
    if (!window.dataLayer) return;

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(event);
  },
};
