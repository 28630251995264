import { useState } from 'react';
import settings from '@/settings';

export interface ZipcodeStateData {
  state: string;
  stateName: string;
  zipcode: string;
}

interface ReebeloLocation {
  country: string;
  zip: string;
  city: string;
  region: string;
  regionName: string;
}

const CUSTOMER_STATE_ZIPCODE = `customer-state-zipcode-${settings.store}`;

export default function useCustomerZipCode() {
  const [data, setData] = useState<ZipcodeStateData | null>(null);

  const getZipcodeStateFromLocalStorage = () => {
    if (typeof window !== 'undefined') {
      const storedStateZipcode = JSON.parse(
        localStorage.getItem(CUSTOMER_STATE_ZIPCODE) || '{}',
      );

      return storedStateZipcode as ZipcodeStateData;
    }

    return null;
  };

  const setToLocalStorage = (formatedState: ZipcodeStateData) => {
    localStorage.setItem(CUSTOMER_STATE_ZIPCODE, JSON.stringify(formatedState));
    setData(formatedState as ZipcodeStateData);
  };

  const setDefaultData = () => {
    if (settings.store === 'reebelo-au') {
      const defaultData = {
        zipcode: '2601',
        state: 'ACT',
        stateName: 'Australian Capital Territory',
      };

      setData(defaultData);
      setToLocalStorage(defaultData);
    }

    if (settings.store === 'reebelo-nz') {
      const defaultData = {
        zipcode: '1010',
        state: 'AUK',
        stateName: 'Auckland',
      };

      setData(defaultData);
      setToLocalStorage(defaultData);
    }

    if (settings.store === 'reebelo-ca') {
      const defaultData = {
        zipcode: 'M4W 2G8',
        state: 'ON',
        stateName: 'Ontario',
      };

      setData(defaultData);
      setToLocalStorage(defaultData);
    }
  };

  const updateDataFromReebeloLocation = async () => {
    try {
      const response = (await fetch('https://location.reebelo.com').then(
        (responseData) => responseData.json(),
      )) as ReebeloLocation;

      if (
        response.country === settings.country_code &&
        !!response.zip &&
        !!response.region
      ) {
        const formatedStateData = {
          zipcode: response.zip,
          state: response.region,
          stateName: response.regionName,
        };

        setData(formatedStateData);
        setToLocalStorage(formatedStateData);
      } else {
        setDefaultData();
      }
    } catch (err) {
      setDefaultData();
    }
  };

  const initialFetching = async () => {
    const storedStateZipcode = getZipcodeStateFromLocalStorage();

    if (!storedStateZipcode?.zipcode || !storedStateZipcode?.state)
      updateDataFromReebeloLocation();
    else setData(storedStateZipcode as ZipcodeStateData);
  };

  return {
    data,
    setToLocalStorage,
    initialFetching,
  };
}
