import { createContext, useContext } from 'react';
import { UseCheckoutResult } from './types';
import useCheckout from './useCheckout';

const CheckoutContext = createContext<UseCheckoutResult | undefined>(undefined);

export const useCheckoutContext = () => {
  const context = useContext(CheckoutContext);

  if (!context) {
    throw new Error(
      'useCheckoutContext must be used within a CheckoutProvider',
    );
  }

  return context;
};

export const CheckoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = useCheckout();

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};
