import { AnalyticsBrowser } from '@segment/analytics-next';
import { SegmentEvent } from '../../events';
import { logger } from '@/lib/logger';
import settings from '../../../../settings';
import { isBot } from '@/lib/utils/bot';

export const shouldTrack = () => !isBot();

export const getDefaultProperties = (): Record<string, any> => ({
  store: settings.store,
});

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_API_KEY!,
});

export const trackEvent = (event: SegmentEvent, properties: object) => {
  try {
    const updatedProperties = { ...getDefaultProperties(), ...properties };

    if (!shouldTrack()) {
      logger.info({ event, updatedProperties }, 'not tracking segment event');

      return;
    }

    logger.debug({ event, updatedProperties }, 'tracking segment event');

    analytics.track(event, updatedProperties);
  } catch (error: any) {
    logger.error({ event, properties, error }, 'error tracking segment event');
  }
};
