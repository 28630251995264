import ShopifyStoreFrontResponse from '@lambda/commons/apis/shopify/types/storefront';
import ShopifyBase from '@lambda/commons/apis/shopify/base';
import settings from '@/settings';

export default new ShopifyBase<ShopifyStoreFrontResponse>({
  store: settings.store,
  password: settings.shopifyStoreFrontApiKey,
  isStoreFront: true,
  shouldThrottle: false,
  version: '2022-10',
});

export const extractOrderIdFromGid = (text?: string): number | undefined => {
  if (!text) return undefined;
  const orderIdRegex = /gid:\/\/shopify\/Order\/(\d+)/;
  const match: RegExpMatchArray | null = text.match(orderIdRegex);

  return match ? Number(match[1]) : undefined;
};
