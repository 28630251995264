import {
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
  createContext,
} from 'react';
import { Workbox } from 'workbox-window';
import { SWRConfig } from 'swr';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import NProgress from 'nprogress';
import type { Cart } from '@lambda/commons/apis/shopify/types/storefront';
import {
  ReebeloStoreT,
  REEBELO_DOMAINS,
  REEBELO_STORE_TO_LOCALE,
} from '@lambda/reebelo/src/types';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import useRouterTransition from '@/lib/use-router-transition';
import settings from '@/settings';
import {
  install,
  triggerInitalEvents,
  triggerInitalGAdsEvents,
} from '@/lib/google-tag-manager';

import '../styles/fonts.scss';
import '../styles/globals.scss';
import '../public/nprogress.css';
import '@lambda/ui/dist/tailwind.css';
import useCustomerZipCode, {
  ZipcodeStateData,
} from '@/lib/use-customer-zipcode';
import useGetChildPageCanonicalUrl from '@/lib/useGetChildPageCanonical';
import { logger } from '@/lib/logger';
import { CustomerProvider } from '@/lib/customer/CustomerContext';
import SegmentAnalytics from '@/lib/analytics/vendor/segment/components';
import GoogleTagManagerAnalytics from '@/lib/analytics/vendor/google/components';
import { useToggleGoogleTopQualityBadge } from '@/lib/use-google-badge';
import { isBot } from '@/lib/utils/bot';
import { CheckoutProvider } from '@/lib/checkout/CheckoutContext';
import { BuybackProvider } from '@/lib/buyback/BuybackContext';
import { FeatureFlagProvider } from '@/lib/feature-flag/FeatureFlagContext';

const sessionSampleRate = isBot() ? 0 : 25;

// Datadog RUM
datadogRum.init({
  applicationId: '7ad78da8-cfad-4dfa-acba-344b917828db',
  clientToken: 'pub1d0dae7e7ee1744171a660ded01b0f0a',
  site: 'us3.datadoghq.com',
  service: 'storefront',
  env: process.env.NEXT_PUBLIC_STAGE || process.env.STAGE,
  version:
    process.env.APP_VERSION || process.env.NEXT_PUBLIC_APP_VERSION || 'dev',
  sessionSampleRate,
  sessionReplaySampleRate: 5,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    /https:\/\/(.+\.)?reebelo\.com/,
    /https:\/\/(.+\.)?reebelo\.com.au/,
    /https:\/\/(.+\.)?reebelo\.ca/,
    /https:\/\/(.+\.)?reebelo\.co.nz/,
    /https:\/\/(.+\.)?reebelo\.sg/,
    /https:\/\/(.+\.)?reebelo\.blue/,
  ],
});

// Datadog logs
datadogLogs.init({
  clientToken: 'pub1d0dae7e7ee1744171a660ded01b0f0a',
  site: 'us3.datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate,
});

const Navbar = dynamic(() => import('@/components/commons/Navbar'));
const Footer = dynamic(() => import('@/components/commons/Footer'), {
  ssr: false,
});
const FeaturedSection = dynamic(
  () => import('@/components/commons/Footer/FeaturedSection'),
  { ssr: false },
);

NProgress.configure({ showSpinner: false });
interface NoopProps {
  children?: ReactNode;
}

const Noop: FC<NoopProps> = ({ children }) => <>{children}</>;
const fetcher = (url: string) =>
  fetch(url).then((response) => {
    if (response.ok) return response.json();
    throw Error(response.statusText);
  });

declare global {
  interface Window {
    zE?: (name: string, envent: string, option?: string) => void;
    merchantverse?: any;
  }
}

type ProviderProps = {
  cartCount: number;
  setCartCount: (value: number) => void;
  cart: Cart | null;
  setCart: (cart: Cart | null) => void;
  isLoadingCart: boolean;
  setIsLoadingCart: (loading: boolean) => void;
  setZipcodeStateData: (data: ZipcodeStateData) => void;
  zipcodeStateData: ZipcodeStateData | null;
};

export const AppCtx = createContext({} as ProviderProps);

const useAppCtxProvider: () => {
  cartCount: number;
  setCartCount: (value: number) => void;
  cart: Cart | null;
  setCart: (cart: Cart | null) => void;
  isLoadingCart: boolean;
  setIsLoadingCart: (loading: boolean) => void;
  setZipcodeStateData: (data: ZipcodeStateData) => void;
  zipcodeStateData: ZipcodeStateData | null;
  Provider: React.Provider<ProviderProps>;
} = () => {
  const [cartCount, setCount] = useState(0);
  const [cart, setCart] = useState<Cart | null>(null);
  const [isLoadingCart, setIsLoadingCart] = useState<boolean>(true);
  const {
    data: zipcodeStateData,
    setToLocalStorage: setZipcodeStateData,
    initialFetching,
  } = useCustomerZipCode();

  const setCartCount = (value: number) => {
    setCount(value);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') initialFetching();
  }, []);

  return {
    cartCount,
    setCartCount,
    cart,
    setCart,
    isLoadingCart,
    setIsLoadingCart,
    Provider: AppCtx.Provider,
    zipcodeStateData,
    setZipcodeStateData,
  };
};

function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop;
  const isTransitioning = useRouterTransition();
  const [cartId, setCartId] = useState('');

  useEffect(() => {
    if (isTransitioning) NProgress.start();
    else NProgress.done();
  }, [isTransitioning]);

  const provider = useRef(new Map());
  const router = useRouter();
  const {
    cartCount,
    setCartCount,
    cart,
    setCart,
    isLoadingCart,
    setIsLoadingCart,
    Provider: AppCtxProvider,
    zipcodeStateData,
    setZipcodeStateData,
  } = useAppCtxProvider();

  const value = {
    cartCount,
    setCartCount,
    cart,
    setCart,
    isLoadingCart,
    setIsLoadingCart,
    zipcodeStateData,
    setZipcodeStateData,
  };

  useEffect(() => {
    if (!('serviceWorker' in navigator) || process.env.STAGE === 'dev') {
      logger.warn('Pwa support is disabled');

      return;
    }

    const wb = new Workbox('/sw.js', { scope: '/' });

    wb.register();
  }, []);

  useToggleGoogleTopQualityBadge();

  useEffect(() => {
    if (cart?.id != null && cart.id.includes('/')) {
      const _cartId = cart.id.split('/').slice(-1)[0];

      logger.info(
        { oldValue: cart.id, newValue: _cartId },
        'setting cart id with new value',
      );

      setCartId(_cartId);
    }
  }, [cart?.id]);

  useEffect(() => {
    // set referrer in session storage only if it's not set
    if (typeof window === 'undefined') return;
    const initialReferrer = sessionStorage.getItem('initialReferrer');

    if (!initialReferrer)
      sessionStorage.setItem('initialReferrer', document.referrer);
  }, []);

  const showHreflangOnAllPages = ['reebelo-us'].includes(settings.store);

  const isChildVariant =
    router.pathname.includes('/collections') && router.asPath.includes('?');

  const childPageCanonicalUrl = useGetChildPageCanonicalUrl(
    router,
    settings.store,
  );

  return (
    <>
      {settings.store === 'reebelo-us' && cartId !== '' && (
        <Script
          strategy="lazyOnload"
          id="sig-api"
          // TODO: this is always empty???
          data-order-session-id={`${cartId}`}
          src="https://cdn-scripts.signifyd.com/api/script-tag.js"
        />
      )}
      {install && (
        // Should not be in <head> https://nextjs.org/docs/messages/no-script-tags-in-head-component
        <Script
          id="gtm-script"
          strategy="afterInteractive"
          onLoad={triggerInitalEvents}
          dangerouslySetInnerHTML={{ __html: install.script }}
        />
      )}
      {install && (
        <Script
          onLoad={triggerInitalGAdsEvents}
          strategy="afterInteractive"
          src="https://www.googletagmanager.com/gtag/js?id=AW-10974721493"
        />
      )}
      {/* Trustpilot */}
      <Script
        strategy="lazyOnload"
        src="https:////widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
      <Script
        strategy="lazyOnload"
        src={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${settings.klaviyo.public_api_key}`}
      />
      <Head>
        {(settings.store === 'reebelo-dev' || process.env.STAGE !== 'prod') && (
          <meta name="robots" content="noindex" />
        )}
        <meta data-store={settings.store} data-stage={process.env.NODE_ENV} />
        {settings.store === 'quista' && (
          <meta
            name="facebook-domain-verification"
            content="nlz65srvmosqjfb7ix1me1i3xaxnt5"
          />
        )}
        <link
          rel="icon"
          type="image/png"
          href="/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href="/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="preload"
          href="/fonts/ModernEra-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href="/fonts/ModernEra-Black.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link
          rel="preload"
          href="/fonts/AcuminProWide-UltraBlack.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link rel="preconnect" href="https://cdn.shopify.com"></link>
        {/* to exclude related page (may unavailable on other store)and URL contained query string */}
        {((!showHreflangOnAllPages &&
          ![
            '/collections',
            '/products',
            '/blogs',
            '/about-us',
            '/help',
            '/reviews',
            '/sell-phone',
            '/track-your-order',
            '/buyback',
            '/buyback-form',
          ].some((s) => router.pathname.includes(s))) ||
          (showHreflangOnAllPages && !isChildVariant)) &&
          [
            ['reebelo-us', 'x-default'],
            ...Object.entries(REEBELO_STORE_TO_LOCALE).filter(
              ([key]) =>
                ![
                  'reebelo-dev',
                  'reebelo-tw',
                  'reebelo-kr',
                  'reebelo-b2b-dev',
                  'reebelo-b2b',
                ].includes(key),
            ),
          ].map(([store, locale]) => (
            <link
              key={`${store}-${locale}`}
              rel="alternate"
              hrefLang={locale}
              href={`https://${REEBELO_DOMAINS[store as ReebeloStoreT]}${
                router.asPath.split('?')[0]
              }`}
            />
          ))}
        {/* for setting the cannonical path */}
        {
          <link
            rel="canonical"
            href={
              childPageCanonicalUrl ??
              `https://${REEBELO_DOMAINS[settings.store]}${
                router.asPath.split('?')[0]
              }`
            }
          />
        }
      </Head>
      <CustomerProvider>
        <FeatureFlagProvider>
          <BuybackProvider>
            <CheckoutProvider>
              <SWRConfig value={{ fetcher, provider: () => provider.current }}>
                <AppCtxProvider value={value}>
                  <Navbar />
                  <Layout pageProps={pageProps}>
                    <Component {...pageProps} />
                  </Layout>
                  <FeaturedSection />
                  <Footer />
                </AppCtxProvider>
              </SWRConfig>
              <SegmentAnalytics />
              <GoogleTagManagerAnalytics />
            </CheckoutProvider>
          </BuybackProvider>
        </FeatureFlagProvider>
      </CustomerProvider>
    </>
  );
}

export default MyApp;
