import {
  StatsigProvider,
  useClientAsyncInit,
  useStatsigUser,
} from '@statsig/react-bindings';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { useEffect } from 'react';
import settings from '@/settings';
import { useCustomerContext } from '../customer/CustomerContext';

const STATSIG_KEY = process.env.NEXT_PUBLIC_STATSIG_KEY;
const tier = process.env.STAGE === 'prod' ? 'production' : 'development';

export const FeatureFlagProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { customer, anonymousId } = useCustomerContext();
  const { updateUserSync } = useStatsigUser();

  const { client } = useClientAsyncInit(STATSIG_KEY || '', {
    userID: customer?.reebeloId || anonymousId || 'unknown',
    email: customer?.email,
    custom: {
      store: settings.store,
    },
  });

  useEffect(() => {
    runStatsigAutoCapture(client);
  }, [client]);

  useEffect(() => {
    updateUserSync({
      userID: customer?.reebeloId || anonymousId || 'unknown',
      email: customer?.email,
    });
  }, [customer, anonymousId]);

  return (
    <>
      {STATSIG_KEY ? (
        <StatsigProvider
          sdkKey={STATSIG_KEY}
          user={{
            userID: customer?.reebeloId || anonymousId || 'unknown',
            email: customer?.email,
          }}
          options={{ environment: { tier } }}
        >
          {children}
        </StatsigProvider>
      ) : (
        children
      )}
    </>
  );
};
