export enum STAGES {
  TEST = 'test',
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}
export const STAGE =
  process.env.NODE_ENV === 'test' ? 'test' : process.env.STAGE || 'dev';
if (process.env.NODE_ENV === STAGES.TEST)
  // @ts-ignore TS don't like process.env to have aboolean value
  process.env = { STAGE: STAGES.TEST, ...process.env };
