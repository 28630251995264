export const formatStatusCodeError = (input?: string) => {
  const backupString =
    'There was an error processing your request. Please try again.';

  if (!input) return backupString;

  if (/\b\d{3}\b/.test(input)) return backupString;

  return input;
};
