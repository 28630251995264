import { useState, useRef, useEffect } from 'react';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import {
  ApplyCheckoutDiscountT,
  CalculateCheckoutTaxT,
  CaptureCheckoutPaymentT,
  CheckoutPaymentT,
  CheckoutT,
  CreateCheckoutPaymentT,
  CreateCheckoutT,
  UpdateCheckoutT,
} from '@lambda/apis/src/checkout/types';
import { CustomerAddress } from '@lambda/apis/src/customer/types';
import { PaymentProcessor } from '@lambda/apis/src/checkout/enum';
import { CartLine } from '@lambda/commons/apis/shopify/types/storefront';
import settings from '@/settings';
import { UseCheckoutResult } from './types';
import { CheckoutStep } from './enums';
import { isAddressValid } from './helpers';
import { formatStatusCodeError } from '../formatStatusCodeError';
import { logger } from '../logger';

const useCheckout = (): UseCheckoutResult => {
  const [checkout, setCheckout] = useState<CheckoutT | undefined>(undefined);
  const [payment, setPayment] = useState<CheckoutPaymentT | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [email, setEmail] = useState<string>(checkout?.email || '');
  const [phone, setPhone] = useState<string>(
    checkout?.billingAddress?.phone || checkout?.shippingAddress?.phone || '',
  );
  const [billingIsShipping, setBillingIsShipping] = useState<boolean>(true);
  const [validBillingAddress, setValidBillingAddress] =
    useState<boolean>(false);
  const [billingAddress, setBillingAddress] = useState<
    CustomerAddress | undefined
  >(checkout?.billingAddress);
  const [validShippingAddress, setValidShippingAddress] =
    useState<boolean>(false);
  const [shippingAddress, setShippingAddress] = useState<
    CustomerAddress | undefined
  >(checkout?.shippingAddress);
  const [isEmailSubscribed, setIsEmailSubscribed] = useState<boolean>(
    settings.email_opt_in_default,
  );
  const [isSmsSubscribed, setIsSmsSubscribed] = useState<boolean | undefined>();
  const [paymentProcessor, setPaymentProcessor] = useState<PaymentProcessor>(
    PaymentProcessor.STRIPE,
  );
  const [isExpressCheckout, setIsExpressCheckout] = useState<boolean>(false);
  const [step, setStep] = useState<CheckoutStep>(CheckoutStep.Shipping);
  const cancelTokenSource = useRef<CancelTokenSource | null>(null);
  const [outOfStockLineItems, setOutOfStockLineItems] = useState<CartLine[]>(
    [],
  );
  const [shouldFetchOutOfStock, setShouldFetchOutOfStock] = useState(false);

  useEffect(() => {
    setValidBillingAddress(isAddressValid(billingAddress).valid);
  }, [billingAddress]);

  useEffect(() => {
    setValidShippingAddress(isAddressValid(shippingAddress).valid);
  }, [shippingAddress]);

  const makeRequest = async <T>(
    config: AxiosRequestConfig,
    onSuccess: (data: T) => void,
  ): Promise<T> => {
    try {
      setError(null);
      setLoading(true);

      if (cancelTokenSource.current) {
        cancelTokenSource.current.cancel(
          'Operation canceled due to new request.',
        );
      }

      cancelTokenSource.current = axios.CancelToken.source();

      const response = await axios.request<T>({
        ...config,
        cancelToken: cancelTokenSource.current.token,
      });

      onSuccess(response.data);
      setLoading(false);

      return response.data;
    } catch (e: any) {
      if (axios.isCancel(e)) {
        logger.log({ err: e, errorMessage: e.message }, 'Request canceled:');

        return undefined as T;
      }
      const errorMessage = formatStatusCodeError(
        e.response?.data?.message || e.message,
      );

      logger.error({ err: e, errorMessage, config }, 'Checkout request error');

      setError(errorMessage);
      setLoading(false);
      throw e;
    }
  };

  const createCheckout = async (request: CreateCheckoutT): Promise<CheckoutT> =>
    makeRequest<CheckoutT>(
      {
        method: 'post',
        url: '/api/checkout',
        data: request,
      },
      (data) => setCheckout(data),
    );

  const fetchCheckout = async (id: string): Promise<CheckoutT | undefined> =>
    makeRequest<CheckoutT>(
      {
        method: 'get',
        url: `/api/checkout/${id}`,
      },
      (data) => setCheckout(data),
    );

  const updateCheckout = async (
    id: string,
    request: UpdateCheckoutT,
  ): Promise<CheckoutT> =>
    makeRequest<CheckoutT>(
      {
        method: 'put',
        url: `/api/checkout/${id}`,
        data: request,
      },
      (data) => setCheckout(data),
    );

  const calculateTax = async (
    id: string,
    request: CalculateCheckoutTaxT,
  ): Promise<CheckoutT> =>
    makeRequest<CheckoutT>(
      {
        method: 'put',
        url: `/api/checkout/${id}/tax`,
        data: request,
      },
      (data) => setCheckout(data),
    );

  const createPayment = async (
    id: string,
    request: CreateCheckoutPaymentT,
  ): Promise<CheckoutPaymentT> =>
    makeRequest<CheckoutPaymentT>(
      {
        method: 'post',
        url: `/api/checkout/${id}/payment`,
        data: request,
      },
      (data) => setPayment(data),
    );

  const capturePayment = async (
    id: string,
    request: CaptureCheckoutPaymentT,
  ): Promise<CheckoutT> =>
    makeRequest<CheckoutT>(
      {
        method: 'post',
        url: `/api/checkout/${id}/capture`,
        data: request,
      },
      (data) => setCheckout(data),
    );

  const applyDiscount = async (
    id: string,
    request: ApplyCheckoutDiscountT,
  ): Promise<CheckoutT> =>
    makeRequest<CheckoutT>(
      {
        method: 'post',
        url: `/api/checkout/${id}/discount`,
        data: request,
      },
      (data) => setCheckout(data),
    );

  const removeDiscounts = async (id: string): Promise<CheckoutT> =>
    makeRequest<CheckoutT>(
      {
        method: 'delete',
        url: `/api/checkout/${id}/discount`,
      },
      (data) => setCheckout(data),
    );

  return {
    // State
    checkout,
    setCheckout,
    payment,
    setPayment,
    step,
    setStep,
    isEmailSubscribed,
    setIsEmailSubscribed,
    isSmsSubscribed,
    setIsSmsSubscribed,
    email,
    setEmail,
    phone,
    setPhone,
    billingIsShipping,
    setBillingIsShipping,
    validBillingAddress,
    setValidBillingAddress,
    billingAddress,
    setBillingAddress,
    validShippingAddress,
    setValidShippingAddress,
    shippingAddress,
    setShippingAddress,
    paymentProcessor,
    setPaymentProcessor,
    isExpressCheckout,
    setIsExpressCheckout,
    loading,
    setLoading,
    processing,
    setProcessing,
    error,
    setError,
    setOutOfStockLineItems,
    outOfStockLineItems,
    shouldFetchOutOfStock,
    setShouldFetchOutOfStock,

    // API methods
    createCheckout,
    fetchCheckout,
    updateCheckout,
    calculateTax,
    createPayment,
    capturePayment,
    applyDiscount,
    removeDiscounts,
  };
};

export default useCheckout;
