import retryFunc, { FailedAttemptError } from 'p-retry';

export const getRandomIntInclusive = (min: number, max: number) => {
  const minInt = Math.ceil(min);
  const maxInt = Math.floor(max);

  return Math.floor(Math.random() * (maxInt - minInt + 1) + minInt);
};

export interface RetryOptions {
  retries: number;
  minTimeout?: number;
  maxTimeout?: number;
  onRetry?: ((e: Error, attempt: number) => void) | undefined;
}

export const retry = <T>(
  func: () => Promise<T>,
  opts: RetryOptions,
): Promise<T> => {
  const retryOptions = {
    retries: opts.retries,
    minTimeout: opts.minTimeout,
    maxTimeout: opts.maxTimeout,
    randomize: true,
    onFailedAttempt: (error: FailedAttemptError) => {
      if (opts.onRetry) opts.onRetry(error, error.attemptNumber);
    },
  };

  return retryFunc(func, retryOptions);
};
