import pino from 'pino';
import { ILogger } from './ILogger';

export class Logger implements ILogger {
  private logger: pino.BaseLogger;

  constructor(traceId: string) {
    this.logger = pino({
      level: process.env.LOG_LEVEL || 'info',
      timestamp: pino.stdTimeFunctions.isoTime,
      formatters: {
        level: (label: string) => ({ level: label.toUpperCase() }),
      },
      base: {
        env: process.env.STAGE,
        store: process.env.STORE,
        service: process.env.DD_SERVICE,
        traceId,
      },
    });
  }

  extend(childTraceId: string) {
    return this;
  }

  info(formatter: any, ...args: any[]) {
    return this.logger.info(formatter, ...args);
  }

  error(formatter: any, ...args: any[]) {
    return this.logger.error(formatter, ...args);
  }

  warn(formatter: any, ...args: any[]) {
    return this.logger.warn(formatter, ...args);
  }

  debug(formatter: any, ...args: any[]) {
    return this.logger.debug(formatter, ...args);
  }
}
