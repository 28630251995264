import { createContext, useContext } from 'react';
import useCustomer from './useCustomer';
import { UseCustomerResult } from './types';

const CustomerContext = createContext<UseCustomerResult | undefined>(undefined);

export const useCustomerContext = () => {
  const context = useContext(CustomerContext);

  if (!context) {
    throw new Error(
      'useCustomerContext must be used within a CustomerProvider',
    );
  }

  return context;
};

export const CustomerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const value = useCustomer();

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};
