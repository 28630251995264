import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';

export const formatPhoneNumber = (
  phoneNumber: string,
  countryCode: CountryCode | string,
) => {
  const parsed = parsePhoneNumberFromString(
    phoneNumber,
    countryCode as CountryCode,
  );

  if (!parsed || !parsed.isValid()) return undefined;

  return parsed.formatInternational().replace(/\s/g, ''); // Remove spaces
};
