import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import type { Logger } from './logging/logger';

export default function logAxios(
  axiosInstance: AxiosInstance,
  logger: Logger,
  format?: {
    request?: <T extends AxiosRequestConfig>(req: any) => T;
    response?: <T extends AxiosResponse<any>>(err: any) => T & { log?: string };
  },
) {
  const formatReq = format?.request || ((req: any) => req);
  const formatRes = format?.response || ((err: any) => err);

  /*
   * This is not secure, as it logs the request body and header
   * which may contains credentials. - Wu
  axiosInstance.interceptors.request.use((req) => {
    const { url, params, data, headers } = formatReq(req);
    const method = req.method?.toUpperCase();
    const args = [params, data].filter(Boolean);

    logger.info(
      {
        request: {
          method,
          url,
          args: { ...args },
          headers: { ...headers },
        },
      },
      '📞 Called Axios',
    );

    return req;
  });
  */

  axiosInstance.interceptors.response.use(undefined, (res) => {
    const status = res.response?.status;
    const method = res.config?.method;
    const url = res.config?.url;

    logger.info(
      {
        status,
        request: {
          method,
          url,
        },
      },
      '❌ Error Axios',
    );

    return Promise.reject(res);
  });
}
