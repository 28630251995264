import { useEffect } from 'react';
import { useRouter } from 'next/router';
import GTM, { GA_UA_ID } from '@/lib/google-tag-manager';
import { logger } from '@/lib/logger';

export default function GoogleTagManagerAnalytics() {
  const router = useRouter();

  useEffect(() => {
    logger.info({ path: router.pathname }, 'handling GTM analytics for page');

    if (GA_UA_ID) {
      GTM.genericEvent({
        event: 'page_view',
        page_title: document.title,
        page_path: router.pathname,
        page_location: window.location.href,
        send_to: GA_UA_ID,
      });
    }
  }, [router.pathname]);

  return null;
}
