// eslint-disable-next-line @typescript-eslint/no-var-requires
const pino = require('pino');

export const logger = pino({
  level: process.env.LOG_LEVEL || 'info',
  timestamp: pino.stdTimeFunctions.isoTime,
  formatters: {
    level: (label: string) => ({ level: label.toUpperCase() }),
  },
  base: {
    env: process.env.NEXT_PUBLIC_STAGE,
    store: process.env.NEXT_PUBLIC_STORE,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    service: 'storefront',
    url: typeof window !== 'undefined' ? window.location.href : null,
  },
  browser: {
    asObject: true,
  },
});
